import React, { useCallback } from "react";

export interface LeadFormContextData {
  productName?: string;
  productChannel?: string;
}

export interface LeadFormContextProps {
  leadFormContextData: LeadFormContextData;
  updateData: (data: LeadFormContextData) => void;
}
export const LeadFormContext = React.createContext<LeadFormContextProps>(
  {} as LeadFormContextProps
);

export const LeadFormContextProvider = ({ children }) => {
  const [leadFormContext, setLeadFormContext] =
    React.useState<LeadFormContextData>({});

  const updateData = useCallback(
    (data: LeadFormContextData) => {
      setLeadFormContext(data);
    },
    [setLeadFormContext]
  );

  const contextData = React.useMemo(
    () => ({
      leadFormContextData: leadFormContext,
      updateData,
    }),
    [leadFormContext, updateData]
  );

  return (
    <LeadFormContext.Provider value={contextData}>
      {children}
    </LeadFormContext.Provider>
  );
};
