import React from "react";
import {
  INavigationMajorGroup,
  INavigation,
  ICountrySelector,
} from "types/Navigation";
import { Language } from "i18n/types";
import { generateUniqSerial } from "utils/uid";
import { resolveAbsoluteUrl } from "utils/route";
import isEmpty from "lodash/isEmpty";
import { replaceContentStackURL } from "@utils";
import { I18nContextData } from "i18n/context/LanguageContext";
import { HeaderTemplates } from "components/HeaderSchema";

type Args = {
  onMouseEnterMajorLink: (
    event: React.MouseEvent,
    item: INavigationMajorGroup
  ) => void;
  onMouseLeaveMajorLink: (
    event: React.MouseEvent,
    item: INavigationMajorGroup
  ) => void;
  onClickMajorLink: (
    event: React.MouseEvent,
    item: INavigationMajorGroup
  ) => void;
  navigation: INavigation;
  countrySelector?: ICountrySelector;
  isMobile: boolean;
  language: Language;
  defaultLanguage: Language;
  languages: Language[];
  countryStackName: string;
  i18nContext: I18nContextData;
  templates: HeaderTemplates
};

export function getNextGenHeaderProps(args: Args) {
  const {
    navigation,
    onMouseEnterMajorLink,
    onMouseLeaveMajorLink,
    onClickMajorLink,
    countrySelector,
    isMobile,
    i18nContext,
    templates
  } = args;

  const titleLinks = (navigation?.titleLink ?? []).map((item) => ({
    id: generateUniqSerial(),
    name: item?.title,
    displayName: item?.title,
    fields: {
      buttonIcon: null,
      title: {
        value: item?.title,
      },
      link: {
        value: {
          href: resolveAbsoluteUrl(item?.url, i18nContext),
          text: item?.title,
          anchor: "",
          class: "",
          title: item?.title,
          querystring: "",
          id: generateUniqSerial(),
          target: item?.openInNewTab ? "_blank" : "_self",
          linktype: !isEmpty(item?.disclosurePopup || {})
            ? "modelpopup"
            : item?.openInNewTab
            ? "external"
            : "internal",
          disclosurepopupcontent: {
            disclosure: {
              value: item?.title,
            },
          },
        },
      },
      pageType: {
        id: generateUniqSerial(),
        fieldType: "Droptree",
        fieldName: "pageType",
        url: "/components/pagetype/business",
        fields: {
          value: {
            value: "Business",
          },
          key: {
            value: "business",
          },
        },
      },
      listType: {
        id: generateUniqSerial(),
        fieldType: "Droplink",
        fieldName: "listType",
        url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
        fields: {
          value: {
            value: "Generic Link",
          },
          key: {
            value: "generic_link",
          },
        },
      },
      gaLabelName: {
        value: item?.title,
      },
      gaCategoryName: {
        value: "Header",
      },
    },
    Children: [],
  }));

  const majorGroupLinks = (navigation?.majorGrouping ?? []).map((item) => ({
    id: generateUniqSerial(),
    name: item?.title,
    displayName: item?.title,
    fields: {
      buttonIcon: {
        id: generateUniqSerial(),
        fieldType: "CustomDroplink",
        fieldName: "buttonIcon",
        url: "/sitecore/content/fwd/global/settings/list/linkvariant/products",
        fields: {
          value: {
            value: item?.title,
          },
          key: {
            value: item?.title,
          },
        },
      },
      title: {
        value: item?.title,
      },
      link: {
        value: {
          href:
            resolveAbsoluteUrl(item?.url, i18nContext).startsWith("#") &&
            resolveAbsoluteUrl(item?.url, i18nContext).endsWith("/")
              ? resolveAbsoluteUrl(item?.url, i18nContext).slice(0, -1)
              : resolveAbsoluteUrl(item?.url, i18nContext),
          text: "",
          anchor: "",
          class: "",
          title: item?.title,
          target: item?.openInNewTab ? "_blank" : "_self",
          querystring: "",
          id: generateUniqSerial(),
          linktype: !isEmpty(item?.disclosurePopup || {})
            ? "modelpopup"
            : item?.openInNewTab
            ? "external"
            : "internal",
          disclosurepopupcontent: {
            disclosure: {
              value: item?.title,
            },
          },
        },
      },
      pageType: {
        id: generateUniqSerial(),
        fieldType: "Droptree",
        fieldName: "pageType",
        url: "/components/pagetype/individual",
        fields: {
          value: {
            value: "Individual",
          },
          key: {
            value: "individual",
          },
        },
      },
      listType: {
        id: generateUniqSerial(),
        fieldType: "Droplink",
        fieldName: "listType",
        url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
        fields: {
          value: {
            value: "Generic Link",
          },
          key: {
            value: "generic_link",
          },
        },
      },
      gaLabelName: {
        value: item?.title,
      },
      gaCategoryName: {
        value: "Header",
      },
      onMouseEnter: (event: React.MouseEvent) =>
        onMouseEnterMajorLink(event, item),
      onMouseLeave: (event: React.MouseEvent) =>
        onMouseLeaveMajorLink(event, item),
      onClick: (event: React.MouseEvent) => onClickMajorLink(event, item),
    },
  }));

  const navigationLogoUrl = replaceContentStackURL(navigation?.logo?.url || "");
  
  const defaultTitleLink = {
    id: generateUniqSerial(),
    name: "personal-insurance",
    displayName: "Personal Insurance",
    fields: {
      title: {
        value: "Personal insurance",
      },
      link: {
        value: {
          href: "/en/products/",
          text: "",
          anchor: "",
          linktype: "internal",
          class: "",
          title: "",
          target: "",
          querystring: "",
          id: generateUniqSerial(),
        },
      },
      buttonIcon: null,
      pageType: {
        id: generateUniqSerial(),
        fieldType: "Droptree",
        fieldName: "pageType",
        url: "/components/pagetype/individual",
        fields: {
          value: {
            value: "Individual",
          },
          key: {
            value: "individual",
          },
        },
      },
      listType: {
        id: generateUniqSerial(),
        fieldType: "Droplink",
        fieldName: "listType",
        url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
        fields: {
          value: {
            value: "Generic Link",
          },
          key: {
            value: "generic_link",
          },
        },
      },
      gaCategoryName: {
        value: "Header",
      },
      gaLabelName: {
        value: "",
      },
    },
    Children: isMobile
      ? [
          {
            fields: {
              collapsibleLinkListProps:
                navigation.majorGrouping?.map((i) => ({
                  ...i,
                  url: resolveAbsoluteUrl(i?.url, i18nContext),
                })) ?? [],
              title: {
                value: "Life insurance",
              },
              link: {
                value: {
                  id: generateUniqSerial(),
                },
              },
            },
          },
        ]
      : majorGroupLinks,
  };

  const generateIconNavigationLinks = () => {
    const items: any = [];

    if (navigation && navigation.iconNavigationLinks) {
      for (const link of navigation.iconNavigationLinks) {
        const item: any = {
          id: generateUniqSerial(),
          name: link.title,
          displayName: link.title,
          fields: {
            enableDropdown: {
              value: link.children ? true : false,
            },
            gaCategoryName: {
              value: "Header",
            },
            gaLabelName: {
              value: link.title,
            },
          },
        };

        switch (link.type) {
          case "contact":
            item.fields.buttonIcon = {
              id: generateUniqSerial(),
              fieldType: "CustomDroplink",
              fieldName: "buttonIcon",
              url: "/sitecore/content/fwd/global/settings/list/linkvariant/call",
              fields: {
                value: {
                  value: link.icon || "contact",
                },
                key: {
                  value: "Contact",
                },
              },
            };
            item.fields.listType = {
              id: generateUniqSerial(),
              fieldType: "Droplink",
              fieldName: "listType",
              url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/call",
              fields: {
                value: {
                  value: "Call",
                },
                key: {
                  value: "call",
                },
              },
            };
            break;
          case "search":
            item.fields.buttonIcon = {
              id: generateUniqSerial(),
              fieldType: "CustomDroplink",
              fieldName: "buttonIcon",
              url: "/sitecore/content/fwd/global/settings/list/linkvariant/search",
              fields: {
                value: {
                  value: "search",
                },
                key: {
                  value: "Search",
                },
              },
            };
            item.fields.listType = {
              id: generateUniqSerial(),
              fieldType: "Droplink",
              fieldName: "listType",
              url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/search",
              fields: {
                value: {
                  value: "Search",
                },
                key: {
                  value: "search",
                },
              },
            };
            break;

          case "general-secondary":
            item.fields.title = {
              value: link.title,
            };
            item.fields.buttonIcon = null;
            item.fields.listType = {
              id: generateUniqSerial(),
              fieldType: "Droplink",
              fieldName: "listType",
              buttonModes: "Secondary",
              url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
              fields: {
                value: {
                  value: "Generic Link",
                },
                key: {
                  value: "generic_link",
                },
              },
            };
            break;
          case "button-link":
              item.fields.title = {
                value: link.title,
              };
              item.fields.buttonIcon = null;
              item.fields.listType = {
                id: generateUniqSerial(),
                fieldType: "Droplink",
                fieldName: "listType",
                url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/button_link",
                fields: {
                  value: {
                    value: "Button Link",
                  },
                  key: {
                    value: "button_link",
                  },
                },
              };
              break;
          case "normal-link":
            item.fields.title = {
              value: link.title,
            };
            item.fields.buttonIcon = null;
            item.fields.listType = {
              id: generateUniqSerial(),
              fieldType: "Droplink",
              fieldName: "listType",
              buttonModes: "SimpleLink",
              url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/normal_link",
              fields: {
                value: {
                  value: "Normal Link",
                },
                key: {
                  value: "normal_link",
                },
              },
            };
            break;
          case "general-login":
            item.fields.isShowInMobileHeaderLanguageSwitch = true;
            item.fields.isDesktopOnly = true;
            item.fields.title = {
              value: link.title,
            };
            item.fields.buttonIcon = null;
            item.fields.listType = {
              id: generateUniqSerial(),
              fieldType: "Droplink",
              fieldName: "listType",
              url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
              fields: {
                value: {
                  value: "Generic Link",
                },
                key: {
                  value: "generic_link",
                },
              },
            };
            break;
          case "flexi-desktop":
            item.fields.isDesktopOnly = true;
            item.fields.title = {
              value: link.title,
            };
            item.fields.buttonIcon = null;
            item.fields.listType = {
              id: generateUniqSerial(),
              fieldType: "Droplink",
              fieldName: "listType",
              url: "",
              fields: {
                value: {
                  value: "Flexi Form",
                },
                key: {
                  value: "flexi_form",
                },
              },
            };
            break;
          case "flexi":
            item.fields.title = {
              value: link.title,
            };
            item.fields.buttonIcon = null;
            item.fields.listType = {
              id: generateUniqSerial(),
              fieldType: "Droplink",
              fieldName: "listType",
              url: "",
              fields: {
                value: {
                  value: "Flexi Form",
                },
                key: {
                  value: "flexi_form",
                },
              },
            };
            break;
          default:
            item.fields.title = {
              value: link.title,
            };
            item.fields.buttonIcon = null;
            item.fields.listType = {
              id: generateUniqSerial(),
              fieldType: "Droplink",
              fieldName: "listType",
              url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
              fields: {
                value: {
                  value: "Generic Link",
                },
                key: {
                  value: "generic_link",
                },
              },
            };
            break;
        }

        if (link.link) {
          item.fields.link = {
            value: {
              href: resolveAbsoluteUrl(link.link, i18nContext),
              url: link.link,
              text: "",
              anchor: "",
              linktype: link.openInNewTab ? "external" : "internal",
              class: "",
              title: "",
              target: "",
              querystring: "",
              id: generateUniqSerial(),
            },
          };
        }

        if (link.children) {
          item.Children = link.children.map((child) => {
            const ret = {
              id: generateUniqSerial(),
              name: child.title,
              displayName: child.title,
              fields: {
                title: {
                  value: child.title,
                },
                link: {
                  value: {
                    href: resolveAbsoluteUrl(child.link, i18nContext),
                    url: child.link,
                    text: "",
                    anchor: "",
                    linktype: child.openInNewTab ? "external" : "internal",
                    class: "",
                    title: "",
                    target: child.openInNewTab ? "_blank" : "_self",
                    querystring: "",
                    openInNewTab: child.openInNewTab,
                    id: generateUniqSerial(),
                  },
                },
                buttonIcon: {
                  id: generateUniqSerial(),
                  fieldType: "CustomDroplink",
                  fieldName: "buttonIcon",
                  url: "/sitecore/content/fwd/global/settings/list/linkvariant/link",
                  fields: {
                    value: {
                      value: child.icon,
                    },
                    key: {
                      value: child.icon,
                    },
                  },
                },
                pageType: {
                  id: generateUniqSerial(),
                  fieldType: "Droptree",
                  fieldName: "pageType",
                  url: "/components/pagetype/individual",
                  fields: {
                    value: {
                      value: "Individual",
                    },
                    key: {
                      value: "individual",
                    },
                  },
                },
                listType:
                  child.type === "chatbot"
                    ? {
                        id: generateUniqSerial(),
                        fieldType: "Droplink",
                        fieldName: "listType",
                        fields: {
                          key: {
                            value: "chat",
                          },
                        },
                      }
                    : child.type === "fib-chatbot"
                    ? {
                        id: generateUniqSerial(),
                        fieldType: "Droplink",
                        fieldName: "listType",
                        fields: {
                          key: {
                            value: "fib-chat",
                          },
                        },
                      }
                    : {
                        id: generateUniqSerial(),
                        fieldType: "Droplink",
                        fieldName: "listType",
                        url: "/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                        fields: {
                          value: {
                            value: "Generic Link",
                          },
                          key: {
                            value: "generic_link",
                          },
                        },
                      },
                gaCategoryName: {
                  value: "Header",
                },
                gaLabelName: {
                  value: child.title,
                },
              },
            };
            return ret;
          });
        }
        items.push(item);
      }
    }

    return items;
  };

  const language = i18nContext.language;

  return {
    translation: {
      visitFWD: countrySelector?.preText,
    },
    fields: {
      displayMode: {
        id: generateUniqSerial(),
        fieldType: "Droplink",
        fieldName: "displayMode",
        url: "/sitecore/content/fwd/global/settings/list/carouselmode/mobile",
        fields: {
          value: {
            value: "Mobile",
          },
          key: {
            value: "Mobile",
          },
        },
      },
      ...(!navigation?.telephone?.hide && {
        telephoneSelector: [
          {
            id: generateUniqSerial(),
            icon: navigation?.telephone?.icon,
            number: navigation?.telephone?.number,
          },
        ],
      }),
      countrySelector: (countrySelector?.list ?? []).map((i) => ({
        id: generateUniqSerial(),
        name: i?.title,
        displayName: i?.title,
        fields: {
          isDefault: {
            value: !!(i?.code === language?.languageCode),
          },
          title: {
            value: i?.title,
          },
          link: {
            value: {
              href: i?.url,
              text: i?.title,
              linktype: "external",
              url: i?.url,
              anchor: "",
              title: i?.title,
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: {
            value: "Header",
          },
          gaLabelName: {
            value: "",
          },
        },
      })),
      mobileLogo: {
        value: {
          src: navigationLogoUrl,
          alt: navigation?.logo?.description || navigation?.logo?.title,
          x1: navigationLogoUrl,
          x2: navigationLogoUrl,
        },
      },
      logo: {
        value: {
          src: navigationLogoUrl,
          alt: navigation?.logo?.description || navigation?.logo?.title,
          x1: navigationLogoUrl,
          x2: navigationLogoUrl,
        },
      },
      logoLink: {
        value: {
          href: resolveAbsoluteUrl(navigation?.logoUrl, i18nContext),
          text: "Home",
          anchor: "",
          linktype: "internal",
          target: "",
          class: "",
          title: "",
          querystring: "",
          id: generateUniqSerial(),
        },
      },
      headerLogo: {
        value: {
          src: resolveAbsoluteUrl(navigation?.logo?.url, i18nContext),
          alt: navigation?.logo?.description || navigation?.logo?.title,
        },
      },
      headerNavigationLinks: [
        templates != HeaderTemplates.Stepper ? defaultTitleLink : null,
        ...titleLinks,
      ],
      iconNavigationLinks: generateIconNavigationLinks(),
      searchPageLink: {
        value: {
          href: "/en/search/",
          text: "",
          anchor: "",
          linktype: "internal",
          class: "",
          title: "",
          target: "",
          querystring: "",
          id: generateUniqSerial(),
        },
      },
      pastSearchRecordsCount: {
        value: "8",
      },
      title: {
        value:
          navigation?.iconNavigationLinks?.find(
            (data: any) => data?.type === "search"
          )?.children?.[0]?.title || undefined,
      },
      placeholderText: {
        value:
          navigation?.iconNavigationLinks?.find(
            (data: any) => data?.type === "search"
          )?.children?.[0]?.description || undefined,
      },
      languageModalDescription: {
        value:
          "We currently working to have this content in your language. You can choose to stay on this page, or proceed to explore other relevant content.",
      },
      languageModalLink: {
        value: {
          href: "/en/",
          text: "Explore",
          anchor: "",
          linktype: "internal",
          class: "",
          title: "Okie",
          querystring: "",
          id: generateUniqSerial(),
        },
      },
      languageModalSecondaryLink: {
        value: "Stay on page",
      },
      languageModalTitle: {
        value: "This content might not be in your language.",
      },
      languageList: args.languages.map((l, i) => ({
        fields: {
          languageName: {
            value: l.displayName,
          },
          defaultLanguage: {
            value:
              args.languages[args.languages.length === 1 ? 0 : (i + 1) % 2]
                .languageCode,
          },
          languageCode: {
            value: l.languageCode,
          },
        },
      })),
      enableLanguageToggle: {
        value: true,
      },
      headerTemplate: {
        value: templates
      }
    },
    params: {
      backgroundColor: "white-orange",
      paddingTop: "None",
      paddingBottom: "None",
    },
    sitecoreContext: {
      language: args.language?.languageCode,
      languageCode: args.language?.languageCode,
      defaultLanguage: args.defaultLanguage?.languageCode,
      availableLanguages: args.languages.map((l) => l.languageCode),
    },
  };
}
