import styled from "styled-components";
import { colorsUtils } from "@d2c-ui-components-react";
import { MegaMenuProps } from "./MegaMenu.types";
import React, { useCallback, useMemo, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import { fwdColors } from "config/fwd-colors";
import isEmpty from "lodash/isEmpty";
import { Link } from "shared-components/Link";
import { Icon } from "shared-components/Icon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Wrapper = styled.div`
  background-color: ${fwdColors.white};
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 14px 0;
`;

const GroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin-top: 16px;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 16px 0 24px 32px;
`;

const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: none;
  }

  &.MuiAccordion-root:before {
    display: none;
  }

  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  & .MuiAccordionSummary-content {
    margin: 0 !important;
  }

  .MuiAccordionSummary-root {
    min-height: auto !important;
    padding: 0 !important;
    .MuiAccordionSummary-expandIcon {
      padding: 0px;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }

  .MuiIconButton-edgeEnd {
    margin-right: -4px;
  }
`;

const IconT = styled((props) => (
  <div {...props}>
    <div className="collapse">n</div>
    <div className="expand">y</div>
  </div>
))`
  & > .expand {
    display: block;
  }
  & > .collapse {
    display: none;
  }
  .Mui-expanded & > .collapse {
    display: block;
  }
  .Mui-expanded & > .expand {
    display: none;
  }
`;

const TypeLabel = styled.div`
  font-weight: 700;
  line-height: 20px;
  color: ${fwdColors.darkGreen};
`;

const GroupLabel = styled(Link)`
  display: flex;
  gap: 8px;
  font-weight: 450;
  line-height: 24px;
`;

const LinkItem = styled(Link)`
  display: flex;
  gap: 10px;
  padding: 8px;

  font-weight: 700;
  line-height: 24px;
`;

const TitleItem = styled.span`
  margin-bottom: 4px;
  color: ${fwdColors.greyDarkest};
  font-size: 14px;
  font-weight: 450;
  line-height: 21px;
`;

const CtaButton = styled(Link)`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 16px;
  color: ${fwdColors.orange};
`;

const HorizontalDivider = styled.hr`
  height: 1px;
  background-color: ${fwdColors.grey};
  opacity: 1;
`;

const GTM_COMPONENT_NAME = "MegaMenuMobile";

const ExpandableMenuItem = (props) => {
  const { label, children, defaultExpanded = false } = props;
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <StyledAccordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={
          <Icon
            size={24}
            color={fwdColors.orange}
            icon={expanded ? "minus" : "plus"}
          />
        }
      >
        <Typography>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
};

export const MegaMenuMobileWithType: React.FC<MegaMenuProps> = ({
  groupWithTypes,
}) => {
  const typeObject =
    groupWithTypes?.[0].types.reduce(
      (acc, type) => ({ ...acc, [type.label]: [] }),
      {}
    ) || {};

  groupWithTypes?.forEach((group) => {
    group.types.forEach((type) => {
      if (typeObject[type.label]) {
        typeObject[type.label].push({
          label: group.label,
          icon: group.icon,
          items: type.items,
          url: type.url,
          gaEventLabel: type.ga_event_label,
          openInNewTab: type.open_in_new_tab,
          ctaButton: {
            label: group.cta_button.label,
            url: group.cta_button.url,
            openInNewTab: group.cta_button.open_in_new_tab,
            gaEventLabel: group.cta_button.ga_event_label,
          },
        });
      }

    });
  });

  const stopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Wrapper>
      {Object.keys(typeObject).map((typeName) => {
        const groups = typeObject[typeName];

        return (
          <ExpandableMenuItem
            label={<TypeLabel>{typeName}</TypeLabel>}
            defaultExpanded={true}
          >
            <GroupsWrapper>
              {groups.map((group) => (
                <ExpandableMenuItem
                  label={
                    <GroupLabel
                      onClick={stopPropagation}
                      href={group.url}
                      gtmComponentName={GTM_COMPONENT_NAME}
                      gaEventLabel={group.gaEventLabel || group.label}
                      openInNewTab={group.openInNewTab}
                    >
                      <Icon size={24} icon={group.icon} /> {group.label}
                    </GroupLabel>
                  }
                >
                  <ItemsWrapper>
                    {group.items.map((item) => {
                      const isLink = !!item.url;

                      if (!isLink) {
                        return <TitleItem>{item.label}</TitleItem>;
                      }

                      return (
                        <LinkItem
                          gtmComponentName={GTM_COMPONENT_NAME}
                          openInNewTab={item.open_in_new_tab}
                          href={item.url}
                          gaEventLabel={item.ga_event_label || item.label}
                        >
                          <Icon
                            size={24}
                            color={fwdColors.orange}
                            icon={item.icon}
                          />
                          {item.label}
                        </LinkItem>
                      );
                    })}
                    <CtaButton
                      gtmComponentName={GTM_COMPONENT_NAME}
                      gaEventLabel={group.ctaButton?.gaEventLabel}
                      openInNewTab={group.ctaButton?.openInNewTab}
                      href={group.ctaButton?.url}
                    >
                      {group.ctaButton.label}
                    </CtaButton>
                  </ItemsWrapper>
                </ExpandableMenuItem>
              ))}
              <HorizontalDivider />
            </GroupsWrapper>
          </ExpandableMenuItem>
        );
      })}
    </Wrapper>
  );
};
