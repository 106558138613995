import { v4 as uuidv4, v1 as uuidv1 } from "uuid";

function generateUniqSerial(): string {
  return uuidv4();

  //return "xxxx-xxxx-xxx-xxxx".replace(/[x]/g, () => {
  //  const r = Math.floor(Math.random() * 16);
  //  return r.toString(16);
  //});
}

function generateUniqId(): string {
  return uuidv1();
}

export { generateUniqSerial, generateUniqId };
