import {
  colorsUtils,
  useResponsive,
  breakpoint,
} from "@d2c-ui-components-react";
import {
  DisclosurePopup,
  Header as NextGenHeader,
} from "@fwd-dep/nextgen-ui-lib";
import { useMediaQuery } from "@material-ui/core";
import { IIconNavigationLink, ILink } from "@types";
import { replaceContentStackURL, setWindowOpen } from "@utils";
import MegaMenu, { MegaMenuProps } from "components/MegaMenu";
import {
  ModalPageNotFoundContext,
  ModalPageNotFoundContextType,
} from "context/ModalPageNotFoundContext";
import I18nContext from "i18n/context/LanguageContext";
import get from "lodash/get";
import noop from "lodash/noop";
import debounce from "lodash/debounce";
import { useRouter } from "next/router";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { transformDisclosurePoppupList } from "services/disclosurePopupService";
import styled, { createGlobalStyle, css } from "styled-components";
import { DisclosurePopupData } from "types/DisclosurePopup.interface";
import { IGtm } from "types/Gtm.interface";
import {
  ICountrySelector,
  INavigation,
  INavigationMajorGroup,
} from "types/Navigation";
import { gtmUltils } from "utils";
import { resolveAbsoluteUrl, scrollIntoViewByHashFromUrl } from "utils/route";
import { getNextGenHeaderProps } from "./getNextGenHeaderProps";
import { MegaMenuMobileWithType } from "components/MegaMenu/MegaMenuMobileWithType";
import { MegaMenuMobileWithTypeColumnUI } from "components/MegaMenu/MegaMenuMobileWithTypeColumnUI";
import { gaEventAction } from "utils/gtm";
import { useOrientation } from "utils/hooks";
import { HeaderTemplates } from "components/HeaderSchema";
import { ModalLeadFormContext } from "context/ModalLeadFormContext";

const NavDialogGlobalStyle = createGlobalStyle`
  #header-modal {
    .country-selector > .MuiSelect-root {
      color: ${colorsUtils.fwdOrange};
    }

    .btn-color-white-default {
      color: ${colorsUtils.fwdOrange};
      background-color: transparent;
      padding: 0;

      &:hover {
        box-shadow: none !important;
      }
    }
  }
`;

const StyledHeader = styled.div<{
  isMobileFullWidthHeader?: boolean;
}>`
  && {
    .MuiLink-primaryLinkText {
      span {
        font-weight: bold;
      }
    }
  }
  .MuiButton-buttonText {
    span {
      font-weight: 700 !important;
    }
  }

  .headerRightContentItem {
    .btn-color-common-default {
      padding: 6px 16px !important;
    }

    &[aria-label="Login"] {
      display: none;
      ${breakpoint("md")`
        display: inline-flex;
      `}
    }
    &.normal_link {
      display: inline-flex;
      margin-left: 10px;
      margin-right: -10px;
      ${breakpoint("md")`
        margin-left: 20px;
        margin-right: 0px;
      `}
    }
    &.button_link {
      .button_link {
        button {
          background-color: ${colorsUtils.fwdOrange};
          color: white;
          padding: 6px 10px !important;
          ${breakpoint("md")`
            padding: 6px 16px !important;
          `}
          &:hover {
            color: ${colorsUtils.fwdOrange};
            background-color: white;
          }
        }
      }
    }
   
  }

  .btn-color-white-default {
    color: ${colorsUtils.fwdOrange};
    background-color: transparent;
    padding: 0;

    &:hover {
      box-shadow: none !important;
    }

    ${breakpoint("xxl")`
      padding: 8px 24px;
      color: #FFFFFF;
      box-shadow: none;
      background-color: ${colorsUtils.fwdOrange};
      &:hover {
        box-shadow: 0 6px 8px 0 rgb(0 0 0 / 20%) !important;
      }
    `}
  }

  .MuiInput-input {
    color: red !important;
  }

  ${({ isMobileFullWidthHeader }) =>
    isMobileFullWidthHeader &&
    css`
      .hideMobile {
        display: none;
      }

      section > div:before {
        left: 0;
        border-bottom-left-radius: 0;
      }

      section {
        pointer-events: unset !important;
      }

      section > div {
        transform: translateY(0) !important;
      }

      .capricorn > div > div {
        background-color: ${colorsUtils.fwdOrange};
      }

      .fwd-logo {
        width: 214px;
        height: 4rem;

        ${breakpoint("sm")`
          width: 100%;
        `}
      }

      .svglogo {
        padding-left: 8px;
      }

      @media screen and (min-width: 374px) {
        .svglogo {
          padding-left: 50px;
        }
      }

      @media screen and (min-width: 410px) {
        .svglogo {
          padding-left: 78px;
        }
      }
      @media screen and (min-width: 1024px) and (max-width: 1140px) {
        .capricorn {
          display: none;
        }

        div[class*="makeStyles-primaryNav"] {
          padding-left: 0;
        }

        .headerRightContentItem {
          margin-left: 0 !important;
        }
      }

      ${breakpoint("sm")`
        .svglogo {
          padding-left: 0;
        }
      `};
    `})}
`;

const MegaMenuPopper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1299;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 24%);
  border-radius: 4px;
  display: none;
`;

const MegaMenuArrow = styled.div`
  width: 3em;
  height: 3em;
  z-index: 1299;
  position: absolute;
  font-size: 7px;
  top: 0;
  margin-top: -0.9em;
  transform: translate(-50%, 0);
  &:before {
    width: 0;
    height: 0;
    margin: auto;
    content: "";
    display: block;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
    border-width: 0 1em 1em 1em;
  }
`;

const OutsideClickOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

interface HeaderProps {
  navigation: INavigation;
  countrySelector?: ICountrySelector;
  onVisibleChange?: (status: boolean) => void;
  isCapricorn: boolean;
  templates: HeaderTemplates;
}

const customDropdownStyle = {
  color: colorsUtils.fwdOrange,
  marginRight: 5,
};

const GTM_COMPONENT_NAME = "Header";
function useTouchDevice() {
  const isTouchOnlyDevice = useMediaQuery(`(pointer: coarse)`);
  const isMouseAndTouchDevice = useMediaQuery(`(any-pointer: coarse)`);
  return isTouchOnlyDevice || isMouseAndTouchDevice;
}

export const Header = ({
  navigation,
  countrySelector,
  onVisibleChange,
  isCapricorn,
  templates,
}: HeaderProps) => {
  const router = useRouter();
  const i18nContext = useContext(I18nContext);
  const modalPageNotFoundContext = useContext(
    ModalPageNotFoundContext
  ) as ModalPageNotFoundContextType;

  const modalLeadFormContext = useContext(ModalLeadFormContext);

  const languages = useMemo(
    () => Object.values(i18nContext.supportedLanguages),
    [i18nContext.supportedLanguages]
  );

  const [megaMenuProps, setMegaMenuProps] = useState<MegaMenuProps>({
    groups: [],
  });
  const isXlScreen = useResponsive({ breakpoint: "xl" });
  const isMobileWidth = useMediaQuery(`(max-width:989px)`);

  const hideMegaMenu = () => {
    const megaMenuEl = document.getElementById("mega-menu");
    if (!megaMenuEl) {
      return;
    }
    if (get(megaMenuEl, "style.display", "") === "none") {
      return;
    }

    megaMenuEl.style.display = "none";
    setMegaMenuProps({
      groups: [],
      isShow: false,
    });
  };

  const onMouseEnterMajorLink = (
    event: React.MouseEvent,
    item: INavigationMajorGroup
  ) => {
    const headerEl = document.getElementById("header");
    const megaMenuEl = document.getElementById("mega-menu");
    const megaMenuArrowEl = document.getElementById("mega-menu-arrow");

    const anchorEl = event.target as HTMLElement;
    if (
      !headerEl ||
      !megaMenuEl ||
      !megaMenuArrowEl ||
      !anchorEl ||
      !item.subGroup?.length
    ) {
      return;
    }
    const isSubGroupWithType = !!item.subGroupWithType;

    const boundingItemRect = anchorEl.getBoundingClientRect();
    //const arrowSpacing = isSubGroupWithType
    //  ? 71
    //  : anchorEl.getBoundingClientRect().left;
    const arrowSpacing = 71;
    megaMenuEl.style.top = `${boundingItemRect.bottom + 35}px`;
    megaMenuEl.style.left = isSubGroupWithType ? "71px" : `${arrowSpacing}px`;
    megaMenuArrowEl.style.left = ` ${
      boundingItemRect.right - boundingItemRect.width / 2 - arrowSpacing
    }px`;
    megaMenuEl.style.display = "block";

    setTimeout(() => {
      const megaMenuRect = megaMenuEl.getBoundingClientRect();
      if (
        megaMenuRect.right >
        (window.innerWidth || document.documentElement.clientWidth)
      ) {
        megaMenuEl.style.left = "0px";
        megaMenuArrowEl.style.left = ` ${
          boundingItemRect.right - boundingItemRect.width / 2
        }px`;
      }
    }, 0);

    setMegaMenuProps({
      groups: item.subGroup,
      groupWithTypes: item.subGroupWithType,
      isShow: true,
      templates: templates,
    });
  };

  const onClickMajorLink = (
    event: React.MouseEvent,
    item: INavigationMajorGroup
  ) => {
    if (!megaMenuProps?.isShow) {
      onMouseEnterMajorLink(event, item);
      return;
    }
    hideMegaMenu();
  };

  const isTouchDevice = useTouchDevice();

  const onMouseLeaveMajorLink = () =>
    // event: React.MouseEvent,
    // item: INavigationMajorGroup
    {
      hideMegaMenu();
    };

  const disclosurePopupList =
    [
      ...navigation.majorGrouping.map((item) => {
        if (item.disclosurePopup) {
          return { ...item.disclosurePopup, id: item.title };
        }
      }),
      ...navigation.titleLink.map((item) => {
        if (item.disclosurePopup) {
          return { ...item.disclosurePopup, id: item.title };
        }
      }),
    ]?.filter((e) => !!e) || [];

  const disclosurePopupProps = disclosurePopupList.length
    ? transformDisclosurePoppupList(disclosurePopupList, i18nContext)
    : null;

  const headerProps = getNextGenHeaderProps({
    ...(isTouchDevice
      ? {
          onClickMajorLink,
          onMouseEnterMajorLink: noop,
          onMouseLeaveMajorLink: noop,
        }
      : {
          onMouseEnterMajorLink,
          onMouseLeaveMajorLink,
        }),

    navigation,
    countrySelector,
    isMobile: isMobileWidth,
    countryStackName: i18nContext.countryStackName,
    language: i18nContext.language,
    defaultLanguage: i18nContext.defaultLanguage,
    languages,
    i18nContext,
    templates
  } as any);

  const [headerState, setHeaderState] = useState({
    headerTransitionStatus: false,
    state: {},
    visible: true,
    logoLoaded: false,
  });

  const setHeaderTransitionStatus = (status: boolean) => {
    setHeaderState({
      ...headerState,
      headerTransitionStatus: status,
    });
  };

  const setHeaderVisible = debounce((visible: boolean) => {
    setHeaderState({
      ...headerState,
      visible: visible,
    });
    onVisibleChange?.(visible);
  }, 20);

  const [disclosurePopups, setDisclosurePopups] = useState({
    availablePopupList: [],
    activePopupKey: null,
    eligiblePopupList: [],
    isRoutedDirectly: false,
  });

  const setDisclosurePopupList = useCallback((payload: any) => {
    const {
      isRoutedDirectly = true,
      activePopupKey = "",
      eligiblePopupList = "",
    } = payload;

    setDisclosurePopups((prev: any) => ({
      ...prev,
      ...(isRoutedDirectly !== ""
        ? { isRoutedDirectly: isRoutedDirectly }
        : null),
      ...(activePopupKey !== "" ? { activePopupKey: activePopupKey } : null),
      ...(eligiblePopupList !== ""
        ? { eligiblePopupList: eligiblePopupList }
        : null),
    }));
  }, []);

  const setAvailableDisclosurePopupList = useCallback(
    (payload: any) => {
      setDisclosurePopups({
        ...disclosurePopups,
        availablePopupList: payload,
      });
    },
    [disclosurePopups]
  );

  const navigate = (href: string, isSelf: boolean) => {
    if (isSelf) {
      setWindowOpen(href, "_self");
    } else {
      setWindowOpen(href, "_blank");
    }
  };

  const changeLocale = (languageCode: string) => {
    const { asPath } = router;
    const url = asPath.replace(i18nContext.language.languageCode, languageCode);
    let urlWithSufficSlash = url.split("?")[0].replace(/\/?$/, "/");
    var anchor = window.location.hash.substring(1);
    if (!!anchor) {
      urlWithSufficSlash = urlWithSufficSlash
        .replace(anchor, "")
        .replace("#", "");
      urlWithSufficSlash = urlWithSufficSlash.replace("//", "/");
    }
    if (i18nContext.siteSettings.validUrls.includes(urlWithSufficSlash)) {
      i18nContext.changeLanguage(languageCode);
      setWindowOpen(url, "_self");
    } else {
      modalPageNotFoundContext.showModal();
    }
  };

  const handleSearch = useCallback(
    (searchText: string) => {
      setWindowOpen(
        resolveAbsoluteUrl(`/search/?q=${searchText}`, i18nContext),
        "_self"
      );
    },
    [i18nContext.language.languageCode]
  );

  const gtmHeaderNavigation = useCallback(
    (gtm: IGtm) => {
      gtmUltils.buttonClick({
        buttonTitle: gtm.buttonTitle,
        destinationUrl: resolveAbsoluteUrl(gtm.destinationUrl, i18nContext),
        componentName: GTM_COMPONENT_NAME,
      });

      gtmUltils.navigation({
        event_action:
          gtm.componentName === "Header-Popper-Menu-Item-Text"
            ? gaEventAction.contact_us
            : gaEventAction.button_click,
        button_title: gtm.buttonTitle,
        destination_url: resolveAbsoluteUrl(gtm.destinationUrl, i18nContext),
        component_name: GTM_COMPONENT_NAME,
        product_affiliation:
          i18nContext.componentConfigs?.gaPageData?.product_affiliation || "",
      });
    },
    [i18nContext]
  );

  useEffect(() => {
    const handleScrollEvent = debounce(() => {
      hideMegaMenu();
    }, 20);

    window.addEventListener("scroll", handleScrollEvent);
    return () => window.removeEventListener("scroll", handleScrollEvent);
  }, []);

  const orientation = useOrientation();

  useEffect(() => {
    if (megaMenuProps.isShow) {
      hideMegaMenu();
    }
  }, [orientation.type]);

  const isMobileFullWidthHeader = useMemo(() => {
    return !!navigation?.iconNavigationLinks?.find(
      (item) => item.type === "flexi-desktop"
    );
  }, [navigation]);

  const openLeadForm = useCallback((e: Event, buttonUrl: string) => {
    if (buttonUrl.includes("[flexi_form]")) {
      e.preventDefault();
      modalLeadFormContext.showModal({
        uid: buttonUrl.split(":")[1],
      });
    }
  }, []);

  return (
    <>
      <NavDialogGlobalStyle />
      <StyledHeader isMobileFullWidthHeader={isMobileFullWidthHeader}>
        <NextGenHeader
          {...headerProps}
          // headerState={headerState}
          setHeaderTransitionStatus={setHeaderTransitionStatus}
          setHeaderVisible={setHeaderVisible}
          notificationExist={true}
          setDisclosurePopupList={setDisclosurePopupList}
          navigate={navigate}
          onLanguageChange={changeLocale}
          handleSearch={handleSearch}
          isFullUrl={false}
          isDisableClick
          titleDropdownStyle={customDropdownStyle}
          gtmPushEvent={(gtm: IGtm) => gtmHeaderNavigation(gtm)}
          isCapricorn={isCapricorn}
          openLeadForm={openLeadForm}
          scrollIntoViewByHashFromUrl={() => {
            scrollIntoViewByHashFromUrl(
              "",
              0,
              isMobileFullWidthHeader ? 56 : 0
            );
          }}
        />
      </StyledHeader>
      <DisclosurePopup
        {...disclosurePopupProps}
        setDisclosurePopupList={setDisclosurePopupList}
        setAvailableDisclosurePopupList={setAvailableDisclosurePopupList}
        availableDisclosurePopupList={disclosurePopups.availablePopupList}
        eligibleDisclosurePopupList={disclosurePopups.eligiblePopupList}
        activeDisclosurePopupKey={disclosurePopups.activePopupKey}
        isDirectRoute={disclosurePopups.isRoutedDirectly}
      />
      <MegaMenuPopper id="mega-menu" onMouseLeave={hideMegaMenu}>
        <MegaMenuArrow id="mega-menu-arrow" />
        {isTouchDevice && <OutsideClickOverlay onClick={hideMegaMenu} />}

        <MegaMenu {...megaMenuProps} />
      </MegaMenuPopper>
    </>
  );
};

interface CSHeaderProps {
  data: {
    logo: {
      url: string;
      title: string;
      description: string;
    };
    logo_url?: string;
    telephone: {
      icon: string;
      number: string;
    };
    navigation_sections: {
      major_grouping: {
        major_group_link: {
          title: string;
          href: string;
        };
        icon_new: string;
        open_link_in_new_tab: boolean;
        disclosure_popup: DisclosurePopupData[];
        modular_blocks: {
          sub_grouping: {
            items: {
              title: string;
              href: string;
              open_in_new_tab: boolean;
            }[];
            sub_grouping_link: {
              title: string;
              href: string;
            };
            open_link_in_new_tab: boolean;
            reference_button_label: string;
          };
          sub_grouping_with_type?: {
            types: {
              label: string;
              items: {
                icon?: string;
                label: string;
                url?: string;
                open_in_new_tab: boolean;
              }[];
            }[];
            sub_grouping_link: {
              title: string;
              href: string;
            };
            open_link_in_new_tab: boolean;
          };
        }[];
      };
      title_link: {
        open_link_in_new_tab: boolean;
        link: {
          title: string;
          href: string;
        };
        disclosure_popup: DisclosurePopupData[];
      };
    }[];
    icon_navigation_links: {
      title: string;
      link: string;
      type: string;
      icon: string;
      open_in_new_tab: boolean;
      children: {
        title: string;
        description?: string;
        link: string;
        type: string;
        open_in_new_tab: boolean;
        icon: string;
      }[];
    }[];
    country_list: any;
    onVisibleChange?: (status: boolean) => void;
    templates: HeaderTemplates;
  };
}

export const CSHeader = (csData: CSHeaderProps) => {
  const {
    logo,
    logo_url,
    telephone,
    navigation_sections = [],
    icon_navigation_links = [],
    country_list,
    onVisibleChange,
    templates,
  } = csData.data || {};

  const defaultTopNav: {
    titleLink: ILink[];
    majorGrouping: INavigationMajorGroup[];
  } = { titleLink: [], majorGrouping: [] };
  const i18nContext = useContext(I18nContext);

  const navigationSections = navigation_sections.reduce(
    (acc: any, navSection) => {
      if (navSection.major_grouping) {
        const {
          major_group_link,
          icon_new,
          open_link_in_new_tab,
          modular_blocks,
          disclosure_popup,
        } = navSection.major_grouping;

        const isSubGroupWithType =
          !!modular_blocks?.[0]?.sub_grouping_with_type;

        const subGroupWithType = modular_blocks?.map(
          (block) => block.sub_grouping_with_type
        );
        const sub = isSubGroupWithType
          ? {
              subGroup: [{ title: "", url: "" }],
              subElement:
                templates && templates === HeaderTemplates.Second ? (
                  <MegaMenuMobileWithTypeColumnUI
                    groups={[]}
                    groupWithTypes={subGroupWithType as any}
                  />
                ) : (
                  <MegaMenuMobileWithType
                    groups={[]}
                    groupWithTypes={subGroupWithType as any}
                  />
                ),
              subGroupWithType,
            }
          : {
              subGroup: modular_blocks?.map((block) => ({
                title: block?.sub_grouping?.sub_grouping_link?.title ?? "",
                url: resolveAbsoluteUrl(block?.sub_grouping?.sub_grouping_link?.href, i18nContext),
                referenceButtonLabel:
                  block?.sub_grouping?.reference_button_label ?? "",
                openInNewTab: !!block?.sub_grouping?.open_link_in_new_tab,
                items: block?.sub_grouping?.items?.map((item: any) => ({
                  title: item?.title ?? "",
                  url: resolveAbsoluteUrl(item?.href, i18nContext) ?? "",
                  openInNewTab: !!item?.open_in_new_tab,
                })),
              })),
            };
        acc.majorGrouping.push({
          title: major_group_link?.title,
          url: major_group_link?.href,
          iconUrl: replaceContentStackURL(icon_new),
          openInNewTab: open_link_in_new_tab,
          disclosurePopup: disclosure_popup?.[0] ?? null,
          ...sub,
        } as INavigationMajorGroup);
      } else if (navSection.title_link) {
        const { open_link_in_new_tab, link, disclosure_popup } =
          navSection.title_link;
        acc.titleLink.push({
          title: link?.title,
          url: link?.href,
          openInNewTab: open_link_in_new_tab,
          disclosurePopup: disclosure_popup?.[0] ?? null,
        } as ILink);
      }

      return acc;
    },
    defaultTopNav
  );
  const iconNavigationLinks: Array<IIconNavigationLink> =
    icon_navigation_links.map(
      ({ title, link, type, icon, open_in_new_tab, children = [] }) => ({
        title,
        link,
        type,
        icon,
        openInNewTab: open_in_new_tab,
        children: children.map((child) => ({
          title: child.title,
          description: child.description,
          link: child.link,
          icon: child.icon,
          openInNewTab: child.open_in_new_tab,
          type: child.type,
        })),
      })
    );

  const data = {
    titleLink: navigationSections?.titleLink ?? [],
    majorGrouping: navigationSections?.majorGrouping ?? [],
    iconNavigationLinks: iconNavigationLinks ?? [],
    logo,
    telephone,
    logoUrl: logo_url,
  };

  const countrySelector: ICountrySelector = {
    preText: get(country_list, "[0].pre_text"),
    list: get(country_list, "[0].country_link_list", []).map(
      ({ country_link }: any) => ({
        title: country_link?.link?.title,
        url: country_link?.link?.href,
        code: country_link?.code,
      })
    ),
  };

  if (
    !data?.titleLink?.length &&
    !data?.majorGrouping?.length &&
    !data?.iconNavigationLinks?.length &&
    !data?.logo?.url
  ) {
    return <></>;
  }

  return (
    <Header
      navigation={data}
      countrySelector={countrySelector}
      onVisibleChange={onVisibleChange}
      templates={templates}
      isCapricorn={
        (csData as any)?.data?.publicConfigurations?.publicCountryCode ===
        "my-capricorn"
      }
    />
  );
};
