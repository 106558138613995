import { I18nContextData } from "i18n/context/LanguageContext";
import isEmpty from "lodash/isEmpty";
import { DisclosurePopupData } from "types/DisclosurePopup.interface";
import { resolveAbsoluteUrl } from "utils/route";
import { generateUniqSerial } from "utils/uid";

export const transformDisclosurePoppupList = (
  data: any[] = [],
  i18nContext: I18nContextData
) => {
  if (isEmpty(data)) return null;
  const props = {
    disclosurePopupList: (data || []).map((item: DisclosurePopupData, i) => ({
      title: {
        value: item?.display_title || "",
      },
      footerText: {
        value: "",
      },
      bottomText: item?.bottom_text || "",
      text: {
        value: item?.description ?? "",
      },
      countdown: {
        countdownSeconds: {
          value: item?.countdown_timer?.countdown_seconds || 10
        },
        enabled: {
          value: !!item?.countdown_timer?.enabled
        },
        title: {
          value: item?.countdown_timer?.title
        }
      },
      sticky: {
        value: item?.sticky || false,
      },
      activeAtEndOfScroll: {
        value: item?.primary_cta?.active_at_end_of_scroll || false,
      },
      isRiskDisclosure: {
        value: true,
      },
      defaultPageLink: {
        value: {
          href: "",
          text: "",
          anchor: "",
          linktype: item?.primary_cta?.open_in_new_tab
            ? "external"
            : "internal",
          id: generateUniqSerial(),
        },
      },
      sessionTimeout: {
        value: "0",
      },
      enableSmallView: {
        value: false,
      },
      frequency: {
        id: "8342b7fa-a8ba-4fd5-b7ca-fea9248e4f42",
        fieldType: "Droplink",
        fieldName: "frequency",
        url: "/sitecore/content/fwd/global/settings/list/disclosure-frequency-type/once-in-browsing-session",
        fields: {
          value: {
            value: "Once in browsing session",
          },
          key: {
            value: "once-in-browsing-session",
          },
        },
      },
      link: {
        value: {
          href: item?.primary_cta?.url
            ? resolveAbsoluteUrl(item.primary_cta?.url, i18nContext)
            : "",
          text: item?.primary_cta?.title ?? "",
          anchor: "",
          linktype: "external",
          url: item?.primary_cta?.url
            ? resolveAbsoluteUrl(item?.primary_cta?.url, i18nContext)
            : "",
          id: generateUniqSerial(),
          target: item?.primary_cta?.open_in_new_tab ? "_blank" : "_self"
        },
      },
      buttonMode: {
        id: "2d7e3979-8dae-42d1-bfdf-45522db9a1b9",
        fieldType: "Droplink",
        fieldName: "buttonMode",
        url: "/sitecore/content/fwd/global/settings/list/button-mode/primary",
        fields: {
          value: {
            value: "Primary",
          },
          key: {
            value: "Primary",
          },
        },
      },
      linkGALabelName: {
        value: item?.primary_cta?.ga_label || item?.primary_cta?.title || "",
      },
      closeCTALabel: {
        value: "",
      },
      isCloseCTA: {
        value: !item?.primary_cta?.url,
      },
      secondaryLinkButtonMode: {
        id: "b096e18e-daae-43a8-87dd-1775ad1f16b6",
        fieldType: "Droplink",
        fieldName: "secondaryLinkButtonMode",
        url: "/sitecore/content/fwd/global/settings/list/button-mode/secondary",
        fields: {
          value: {
            value: "Secondary",
          },
          key: {
            value: "Secondary",
          },
        },
      },
      secondaryLinkGALabelName: {
        value:
          item?.secondary_cta?.ga_label || item?.secondary_cta?.title || "",
      },
      secondaryLink: {
        value: {
          href: "",
          text: item?.secondary_cta?.title ?? "",
          anchor: "",
          linktype: "internal",
          url: "",
          id: generateUniqSerial(),
        },
      },
      secondaryLinkButtonIcon: {
        id: "7ea908fb-506f-45e9-ab04-2b33b5dcde2a",
        fieldType: "CustomDroplink",
        fieldName: "secondaryLinkButtonIcon",
        url: "/sitecore/content/fwd/global/settings/list/linkvariant/link",
        fields: {
          value: {
            value: "arrow-right",
          },
          key: {
            value: "Link",
          },
        },
      },
      secondaryCloseCTALabel: {
        value: item?.secondary_cta?.title ?? "",
      },
      isSecondaryCloseCTA: {
        value: true,
      },
      FormParameters: {
        value: {},
      },
      IsFormPost: {
        value: false,
      },
      id: item?.id || `${i}`,
    })),
    routeLevelDisclosures: {
      riskDisclosure: null,
      nonRiskDisclosure: null,
    },
    pageRouteItemId: "9cb72689-fa18-4900-b876-3ae397d3b009",
    isRequestFromExternalSource: true,
  };
  return props;
};
