import Modal from "@material-ui/core/Modal";
import React from "react";
import { EnquiryLeadPopupForm } from "shared-components/EnquiryLeadForm";
import styled from "styled-components";
import { breakpoint } from "@d2c-ui-components-react";
import { Button } from "@fwd-dep/nextgen-ui-lib";
import { isMobile } from "utils/browser";

const ButtonClose = styled.div`
  width: 32px;
  height: 32px;
  position: fixed;
  top: 16px;
  right: 24px;
  z-index: 9999;

  ${breakpoint("md")`
    right: 48px;
    top: 48px;
  `}

  .MuiButton-rootBtn {
    width: 32px;
    height: 32px;
    min-width: 32px;
    background-color: #fff;
    border-radius: 50%;
    padding: 0;
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: #183028;
      }
    }
  }
`;

const ModalBody = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  mstransform: translateY(-50%);
  margin: 0 16px;
  width: auto;
  max-width: 1099px;
  max-height: calc(100% - 64px);
  overflow: auto;

  ${breakpoint("md")`
    margin: 0 auto;
  `}
`;

const ModalContent = styled.div<{ isMobile: boolean }>`
  background-color: transparent;
  border-radius: 24px;
  width: 100%;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  div[class^="BlockContainer__Container"] {
    border-radius: 16px 16px 0 0;
    margin-top: -16px;
  }
  div[class^="BlockContainer__Wrapper"] {
    padding: 16px 16px 24px 16px;
    min-height: 400px;
    background-color: transparent;
    display: flex;
    align-items: center;
  }

  div[class^="EnquiryLeadForm__FormWrapper"] {
    padding: 0;
    background-color: transparent;
  }
  div[class*="buttonBox"] {
    justify-content: flex-start;
  }

  ${breakpoint("md")`
    flex-direction: row;
    div[class^="BlockContainer__Wrapper"] {
      padding: 48px;
      min-height: 720px;
    }
    div[class^="BlockContainer__Container"] {
      border-radius: 0;
      margin-top: 0;
    }
  `}
  picture {
    display: none !important;
  }
  .MuiFormControlLabel-root {
    padding-right: 0;
    margin-right: 0;
  }
`;

const BackgroundImage = styled.div<{ src?: string }>`
  max-width: 100%;
  width: 100%;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  height: 173px;
  ${breakpoint("md")`
    height: auto;
    max-width: 503px;
  `}
`;

export type ModalLeadFormContextType = {
  showModal: (data?: ModalLeadFormProps) => void;
};

export const ModalLeadFormContext = React.createContext(
  {} as ModalLeadFormContextType
);

export type ModalLeadFormProps = {
  uid?: string;
  title?: string;
  description?: string;
  backgroundUrl?: string;
  url?: string;
  productName?: string;
};

export function ModalLeadFormProvider(props: any) {
  const CSFooter = props?.value?.data?.layout?.find(
    ({ nameComponent }: any) => {
      return nameComponent == "CSFooter";
    }
  );

  const leadFormRefs = CSFooter?.dataComponent?.flexible_form_reference || [];
  const [open, setOpen] = React.useState(false);
  const [csMapData, setCsMapData] = React.useState({
    data: {},
    titleCx: "",
    backgroundUrlCx: "",
    defaultProductName: "",
  });
  
  const showModal = (data?: any) => {
    let uid = data.uid;
    if (!uid && data?.url) {
      const partUrls = data.url.split(":");
      uid = partUrls.length > 0 ? partUrls[1] : "";
    }
    const flexiFormSelected = leadFormRefs.filter((f: any) => {
      return f.uid === uid.replace("/", "");
    });
    if (flexiFormSelected && flexiFormSelected.length > 0) {
      const defaultBackground = isMobile()
        ? flexiFormSelected?.[0]?.form_settings?.background_mobile?.url
        : flexiFormSelected?.[0]?.form_settings?.background_desktop?.url;

      setCsMapData({
        data: {
          reference: flexiFormSelected,
        },
        titleCx: data?.title || "",
        backgroundUrlCx: data?.backgroundUrl || defaultBackground || "",
        defaultProductName: data?.productName || "",
      });
      setOpen(true);
    }
  };

  return (
    <ModalLeadFormContext.Provider
      value={{
        showModal,
      }}
    >
      {props.children}
      {open && (
        <ButtonClose>
          <Button
            title="Close"
            aria-label="Close"
            icon="close"
            alt={"close"}
            onClick={() => setOpen(false)}
          />
        </ButtonClose>
      )}

      <Modal
        open={open}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableBackdropClick={true}
        onClose={() => setOpen(false)}
      >
        <ModalBody>
          <ModalContent isMobile={isMobile()}>
            <BackgroundImage src={csMapData.backgroundUrlCx} />
            {open && (
              <EnquiryLeadPopupForm
                data={csMapData?.data}
                titleCx={csMapData.titleCx}
                defaultProductName={csMapData.defaultProductName}
              />
            )}
          </ModalContent>
        </ModalBody>
      </Modal>
    </ModalLeadFormContext.Provider>
  );
}
