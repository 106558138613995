import styled from "styled-components";
import { MegaMenuProps } from "./MegaMenu.types";
import React, { useCallback, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { fwdColors } from "config/fwd-colors";
import { Link } from "shared-components/Link";
import { Icon } from "shared-components/Icon";

const Wrapper = styled.div`
  background-color: ${fwdColors.white};
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 14px 0;
`;

const GroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin-top: 16px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 8px 0px 0px 32px;
`;

const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: none;
  }

  &.MuiAccordion-root:before {
    display: none;
  }

  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  & .MuiAccordionSummary-content {
    margin: 0 !important;
  }

  .MuiAccordionSummary-root {
    min-height: auto !important;
    padding: 0 !important;
    .MuiAccordionSummary-expandIcon {
      padding: 0px;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }

  .MuiIconButton-edgeEnd {
    margin-right: -4px;
  }
`;

const TypeLabel = styled.div`
  font-weight: 700;
  line-height: 20px;
  color: ${fwdColors.darkGreen};
`;

const TypeLabelWithLink = styled(Link)`
  display: flex;
  gap: 8px;
  font-weight: 450;
  line-height: 24px;
  svg {
    path {
      fill: #e87722!important;
    }
  }
`;


const TypeLabelText = styled.div`
  display: flex;
  gap: 8px;
  font-weight: 450;
  line-height: 24px;
  svg {
    path {
      fill: #e87722!important;
    }
  }
`;

const LinkItem = styled(Link)`
  display: flex;
  gap: 10px;
  padding: 8px 8px 8px 16px;
  line-height: 24px;
  position: relative;
  align-items: center;
  
  ::after {
    position: absolute;
    content: " ";
    height: 6px;
    width: 6px;
    left: 0;
    background-color: black;
    border-radius: 50%;
    top: 18px;
  }
  :last-child {
    padding-bottom: 4px;
  }
`;

const TitleItem = styled.span`
  margin-bottom: 4px;
  color: ${fwdColors.greyDarkest};
  font-size: 14px;
  font-weight: 450;
  line-height: 21px;
`;

const CtaButton = styled(Link)`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 16px;
  color: ${fwdColors.orange};
`;

const HorizontalDivider = styled.hr`
  height: 1px;
  background-color: ${fwdColors.grey};
  opacity: 1;
`;

const HighlighTag = styled.span`
  background-color: #e87722;
  color: #fff;
  padding: 0px 3px;
  display: inline-block;
  margin-left: 0px;
  border-radius: 4px;
  font-size: 10px;
  height: 16px;
  line-height: 16px;
`;


const GTM_COMPONENT_NAME = "MegaMenuMobile";

const ExpandableMenuItem = (props) => {
  const { label, children, defaultExpanded = false, expandIcon = true } = props;
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <StyledAccordion expanded={expanded} onChange={handleChange}>
      {expandIcon ? (<AccordionSummary
        expandIcon={
          <Icon
            size={24}
            color={fwdColors.orange}
            icon={expanded ? "minus" : "plus"}
          />
        }
      >
        <Typography>{label}</Typography>
      </AccordionSummary>) : (<AccordionSummary> <Typography>{label}</Typography></AccordionSummary>)}

      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
};

export const MegaMenuMobileWithTypeColumnUI: React.FC<MegaMenuProps> = ({
  groupWithTypes,
}) => {
  const stopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Wrapper>
      {groupWithTypes?.map((group, idx) => {

        return (
          <ExpandableMenuItem
            label={<TypeLabel>{group.label}</TypeLabel>}
            defaultExpanded={idx === 0}
          >
            <GroupsWrapper>
              {group?.types.map((type) => (
                <ExpandableMenuItem
                  label={
                    type.url ?
                      (<TypeLabelWithLink
                        onClick={stopPropagation}
                        href={type.url}
                        gtmComponentName={GTM_COMPONENT_NAME}
                        gaEventLabel={type.ga_event_label || type.label}
                        openInNewTab={true}
                      >
                        <Icon size={24} icon={type.icon} /> {type.label}
                      </TypeLabelWithLink>)
                      : (<TypeLabelText>
                        <Icon size={24} icon={type.icon} /> {type.label}
                      </TypeLabelText>)
                  }
                  expandIcon={type.items?.length > 0}
                >
                  {type.items?.length > 0 && (
                    <ItemsWrapper>
                      {type.items.map((item) => {
                        const isLink = !!item.url;

                        if (!isLink) {
                          return <TitleItem>{item.label}</TitleItem>;
                        }

                        return (
                          <LinkItem
                            gtmComponentName={GTM_COMPONENT_NAME}
                            openInNewTab={item.open_in_new_tab}
                            href={item.url}
                            gaEventLabel={item.label}
                          >
                            <Icon
                              size={24}
                              color={fwdColors.orange}
                              icon={item.icon}
                            />
                            {item.label}
                            {item.highlight_tags && <HighlighTag>{item.highlight_tags}</HighlighTag>}
                          </LinkItem>
                        );
                      })}
                      <CtaButton
                        gtmComponentName={GTM_COMPONENT_NAME}
                        gaEventLabel={group.cta_button.ga_event_label}
                        openInNewTab={group.cta_button.open_in_new_tab}
                        href={group.cta_button?.url}
                      >
                        {group.cta_button.label}
                      </CtaButton>
                    </ItemsWrapper>
                  )}
                </ExpandableMenuItem>
              ))}
              <HorizontalDivider />
            </GroupsWrapper>
          </ExpandableMenuItem>
        );
      })}
    </Wrapper>
  );
};
